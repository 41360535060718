import { Spiner } from "@components/loaders/spiner";
import { Bills } from "@services/dtos/finance.dto";
import { FinanceService } from "@services/finance.service";
import { SchoolService } from "@services/school.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import { useEffect, useState } from "react";
import { FaChevronDown, FaFileInvoiceDollar } from "react-icons/fa";
import { FcFinePrint } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

export const OneBillPage = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState<"10" | "20" | "50">("10");
  const [periode, setPeriode] = useState<string>("");
  const [years, setYears] = useState<{ value: string; label: string }[]>([]);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  const { data: dataNis } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    queryFn: SchoolService.studentBio,
  });
  const nis = dataNis?.data?.nis ?? "";
  const { data, isPending } = useQuery({
    queryKey: [
      FinanceService.queries.GET_FINANCE_ONE_BILL,
      nis,
      limit,
      periode,
    ],
    queryFn: () => FinanceService.oneBill({ nis, limit, periode }),
  });

  const currentYear = new Date().getFullYear();
  const startYear = 2020;
  const endYear = currentYear + 2;
  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Tagihan Non Rutin",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ leading: "back", title: "Tagihan Non Rutin" });
    const options = [];
    for (let i = endYear; i >= startYear; i--) {
      options.push({ value: `${i - 1}_${i}`, label: `${i - 1}-${i}` });
    }
    setPeriode(`${endYear - 1}_${endYear}`);
    setYears(options);
  }, [setFooter, setHeader, setAppBar]);

  const onOpenDetail = (bill: Bills) => {
    navigate(linkPage.FINANCE_BILL_DETAIL, { state: bill });
  };

  return (
    <div className="card card-style">
      <div className="content mb-0">
        <p className="mb-3">
          Data yang ditampilkan hanya {limit} data terakhir
        </p>

        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Filter Jumlah ditampilkan
          </label>
          <select
            defaultValue="10"
            onChange={(e) => setLimit(e.target.value as "10" | "20" | "50")}
            className="bg-theme"
          >
            <option value="default" disabled>
              Filter Jumlah ditampilkan
            </option>
            <option value="10">10 Data Terakhir</option>
            <option value="25">25 Data Terakhir</option>
            <option value="50">50 Data Terakhir</option>
            <option value="100">100 Data Terakhir</option>
          </select>
          <span>
            <i>
              <FaChevronDown />
            </i>
          </span>
        </div>

        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Periode
          </label>
          <select
            onChange={(e) => setPeriode(e.target.value)}
            className="bg-theme"
            defaultValue={periode}
          >
            <option value="default" disabled>
              Pilih Periode
            </option>
            {years.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <span>
            <i>
              <FaChevronDown />
            </i>
          </span>
        </div>

        {isPending ? (
          <div className="d-flex p-3">
            <Spiner className="!tw-text-gray-200 tw-fill-gray-500 dark:tw-fill-white dark:!tw-text-gray-600 tw-w-8 tw-h-8 m-auto " />
          </div>
        ) : data?.data != null && data.data.length > 0 ? (
          <div className="list-group list-group-flush">
            {data.data.map((item, idx) => (
              <div
                key={idx}
                className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                onClick={() => onOpenDetail(item)}
              >
                <div className="rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto bg-magenta-dark">
                  <FaFileInvoiceDollar className="m-auto" size={18} />
                </div>
                <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                  <span className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                    {item.tagihan} - {item.tahunajaran}
                  </span>

                  <strong className="tw-font-medium tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                    <p className="mb-0 d-inline-block tw-w-[40px]">Total </p>
                    <p className="mb-0 d-inline-block">
                      : {toRp(item.nominal ?? 0)}
                    </p>
                  </strong>
                  <strong className="tw-font-medium tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                    <p className="mb-0 d-inline-block tw-w-[40px]">Sisa </p>
                    <p className="mb-0 d-inline-block">
                      : {toRp(item.sisabayar ?? 0)}
                    </p>
                  </strong>
                </div>
                <div className="my-auto">
                  {item.terbayar === "Y" ? (
                    <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-ml-2">
                      LUNAS
                    </span>
                  ) : (
                    <span className="badge bg-red-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-ml-2">
                      BELUM LUNAS
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="tw-p-8 text-center ">
            <FcFinePrint size={50} />
            <div>Data tidak ditemukan</div>
          </div>
        )}
      </div>
    </div>
  );
};
