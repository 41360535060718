import {
  PopupCustom,
  PopupError,
  PopupSuccess,
} from "@components/generals/popup";
import { Spiner, SpinerPage } from "@components/loaders/spiner";
import { Bills, Methods } from "@services/dtos/finance.dto";
import { FinanceService } from "@services/finance.service";
import { SchoolService } from "@services/school.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation, useQuery } from "@tanstack/react-query";
import { formatValue, toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import moment from "moment";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { FaChevronDown, FaFileInvoiceDollar } from "react-icons/fa";
import { FcFinePrint } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";

export const VirtualAccountsRequestPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { pushNotification } = useNotificationStore((state) => state);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const [popupPayment, setPopupPayment] = useState<boolean>(false);
  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);
  const [popupError, setPopupError] = useState<boolean>(false);
  const [result, setResult] = useState<any>({});
  const [adminFee, setAdminFee] = useState<number>(0);

  const [paymentMethod, setPaymentMethod] = useState<"va" | "saving">("va");
  const [rekening, setRekening] = useState<Methods>();
  const [pocket, setPocket] = useState<string>("UANGSAKU");
  const [pocketNominal, setPocketNominal] = useState<string>("");
  const [bills, setBills] = useState<
    { id: string; notes: string; amount: number; tempo: Date; tipe: string }[]
  >([]);
  const [idxDisabledPayment, setIdxDisabledPayment] = useState<
    { index: number; id: string; amount: number }[]
  >([]);

  const { data: bio } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    queryFn: SchoolService.studentBio,
  });
  const { data, isPending } = useQuery({
    queryKey: [FinanceService.queries.GET_FINANCE_BILLS_PAID_OF],
    queryFn: () => FinanceService.billsPaidOf(),
  });
  const { data: pockets } = useQuery({
    queryKey: [FinanceService.queries.GET_FINANCE_POCKET],
    queryFn: () => FinanceService.pocket(),
  });
  const { data: methods } = useQuery({
    queryKey: [FinanceService.queries.GET_FINANCE_METHODS],
    queryFn: () => FinanceService.methods(),
  });

  const { mutate: mutateVA, isPending: isPendingPaymentVa } = useMutation({
    mutationFn: FinanceService.virtualAccountRequest,
    onSuccess: (response) => {
      if (response.success) {
        setResult(response.data);
        setPopupPayment(false);
        setPopupSuccess(true);
      } else {
        // pushNotification({
        //   type: "error",
        //   message: response.message,
        // });
        setPopupPayment(false);
        setPopupError(true);
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  const { mutate: mutatePocket, isPending: isPendingPaymentPocket } =
    useMutation({
      mutationFn: FinanceService.paymentWithPocket,
      onSuccess: (response) => {
        if (response.success) {
          setPopupPayment(false);
          setPopupSuccess(true);
        } else {
          pushNotification({
            type: "error",
            message: response.message,
          });
          // setPopupPayment(false);
          // setPopupError(true);
        }
      },
      onError: (error: any) => {
        pushNotification({
          type: "error",
          message: error.message ?? "Internal Error",
        });
      },
    });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: state.title ?? "Request VA",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ leading: "back", title: state.title ?? "Request VA" });
  }, [setFooter, setHeader, setAppBar, state]);

  const onPayment = () => {
    const nominal = pocketNominal.replace(/,/g, "");
    if (pocketNominal === "" && bills.length < 1) {
      pushNotification({
        type: "error",
        message: "Nominal atau tagihan harus ada yang diisi",
      });
      return;
    }
    if (
      bills.map((i) => i.amount).reduce((a, b) => a + b, 0) +
        parseInt(nominal) <
      10000
    ) {
      pushNotification({
        type: "error",
        message: "Nominal topup minimal Rp 10.000",
      });
      return;
    }
    if (rekening === undefined && paymentMethod === "va") {
      pushNotification({
        type: "error",
        message: "Pilih rekening Bank terlebih dahulu",
      });
      return;
    }
    setPocketNominal(pocketNominal.replace(/,/g, ""));
    setPopupPayment(true);
  };

  const onConfirmPayment = () => {
    if (paymentMethod === "va") {
      mutateVA({
        id_rekening: rekening?.id ?? "",
        nis: bio?.data?.nis ?? "",
        data_saldo: [
          {
            nominal: pocketNominal === "" ? "0" : pocketNominal,
            type_saldo: pocket,
          },
        ],
        data: bills.map((item) => ({
          id_tagihan: item.id,
          tagihan: item.notes,
          dibayar: item.amount.toString(),
        })),
      });
    } else {
      mutatePocket({
        nis: bio?.data?.nis ?? "",
        tanggal: moment().format("YYYY-MM-DD"),
        catatan: "Pembayaran via tabungan",
        transfer: "TABUNGAN",
        data: bills.map((item) => ({
          nourut: item.id,
          dibayar: item.amount.toString(),
          tagihan: item.notes,
        })),
      });
    }
  };

  const onChangeBill = (val: string, bill: Bills) => {
    const current = bills.filter(
      (item) => item.id !== bill.nourut
      // && moment(bill.jatuhTempo).isAfter(item.tempo)
    );

    if (bill.tipe === "bulanan") {
      onDisabledPayment(val, bill.nourut, bill.tipe);
    }

    if (val === "") {
      bill.tipe === "bulanan"
        ? onDisabledPayment(val, bill.nourut, bill.tipe)
        : setBills(current);

      return;
    }

    setBills(
      current.concat({
        id: bill.nourut,
        notes: `${bill.tagihan} - ${bill.tahunajaran}`,
        amount: parseInt(val),
        tempo: moment(bill.jatuhTempo).toDate(),
        tipe: bill.tipe,
      })
    );
  };

  const checkData =
    data?.data
      ?.filter((item) => item.tipe === "bulanan")
      .map((item, index) => ({
        index: index,
        id: item.nourut,
        amount: item.sisabayar,
      })) ?? [];

  const onDisabledPayment = (val: string, nourut: string, tipe: string) => {
    const nourutIndex = checkData.findIndex((item) => item.id === nourut);

    if (
      val !== "" &&
      nourutIndex !== -1 &&
      nourutIndex < checkData.length - 1
    ) {
      const dataDisabled = [...idxDisabledPayment, checkData[nourutIndex + 1]];
      const uniqueElements = lodash.uniqBy(dataDisabled, "id");

      setIdxDisabledPayment(uniqueElements);
    } else if (val === "" || val === null || val === undefined) {
      const updatedData = idxDisabledPayment.filter(
        (item) => item.index <= nourutIndex
      );
      const billsIndex = checkData.findIndex((item) => item.id === nourut);

      if (billsIndex !== -1 && billsIndex < bills.length) {
        const updatedBills = bills.filter((item, index) => {
          return index < billsIndex || !(item.tipe === "bulanan");
        });
        setBills(updatedBills);
      }
      setIdxDisabledPayment(updatedData);
    }
  };

  const checkFirstIndexArr = (nourut: string) => {
    const currentArr = data?.data?.filter((item) => item.tipe === "bulanan");
    return (
      currentArr && currentArr.length > 0 && currentArr[0].nourut === nourut
    );
  };

  useEffect(() => {
    if (checkData.length > 0) {
      setIdxDisabledPayment([
        {
          index: checkData[0].index,
          id: checkData[0].id,
          amount: checkData[0].amount,
        },
      ]);
    }
  }, [data]);

  // useEffect(() => {
  //   if ((methods?.data ?? []).length > 0) {
  //     const dataRekening = (methods?.data ?? [])[0];
  //     setRekening(dataRekening);
  //     setAdminFee(dataRekening?.type === "MANUAL" ? 0 : 3000);
  //   }
  // }, [methods]);

  return (
    <>
      <SpinerPage show={isPendingPaymentVa || isPendingPaymentPocket} />
      {state.tagihan && (
        <div className="card card-style !tw-mb-4">
          <div className="content mb-0">
            <div className="input-style has-borders input-style-always-active no-icon tw-grow">
              <label htmlFor="form5" className="color-highlight font-500">
                Pilih Sumber
              </label>
              <select
                defaultValue={paymentMethod}
                className="bg-theme"
                onChange={(e) =>
                  setPaymentMethod(e.target.value as "va" | "saving")
                }
              >
                <option value="default" disabled>
                  Pilih Sumber
                </option>
                <option value="va">Virtual Account (VA)</option>
                <option value="saving">
                  Tabungan (
                  {toRp(
                    pockets?.data?.find(
                      (item) => item.typePocket === "TABUNGAN"
                    )?.balance ?? 0
                  )}
                  )
                </option>
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
          </div>
        </div>
      )}

      {!state.tagihan && paymentMethod === "va" && (
        <div className="card card-style !tw-mb-4">
          <div className="content mb-0">
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <div className="input-style has-borders input-style-always-active no-icon">
                <label htmlFor="form5" className="color-highlight font-500">
                  Pilih Kantong
                </label>
                <select
                  defaultValue={pocket}
                  className="bg-theme"
                  onChange={(e) => setPocket(e.target.value)}
                >
                  <option value="default" disabled>
                    Pilih Kantong
                  </option>
                  {/* {pockets?.data?.map((item, idx) => (
                    <option key={idx} value={item.typePocket}>
                      {item.namePocket}
                    </option>
                  ))} */}
                  <option value="UANGSKU">Uang Saku</option>
                  <option value="LAUNDRY">Laundry</option>
                  <option value="ABO">Al-Barokah</option>
                  <option value="TABUNGAN">Tabungan</option>
                  <option value="QURBAN">Qurban</option>
                  <option value="UMROH">Umroh</option>
                </select>
                <span>
                  <i>
                    <FaChevronDown />
                  </i>
                </span>
              </div>
              <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                <input
                  type="text"
                  className="form-control bg-theme"
                  placeholder="Nominal"
                  inputMode="numeric"
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = formatValue(value);
                    setPocketNominal(e.target.value.replace(/\D/g, ","));
                  }}
                  onKeyDown={(event) => {
                    if (!/[0-9]|Backspace|Tab/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <label
                  htmlFor="form1"
                  className="color-highlight font-400 font-13"
                >
                  Nominal
                </label>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.tagihan && (
        <div className="card card-style !tw-mb-4">
          <div className="content my-2">
            {isPending ? (
              <div className="d-flex p-3">
                <Spiner className="!tw-text-gray-200 tw-fill-gray-500 dark:tw-fill-white dark:!tw-text-gray-600 tw-w-8 tw-h-8 m-auto " />
              </div>
            ) : data?.data != null && data.data.length > 0 ? (
              <div className="list-group list-group-flush">
                {data.data.map((item, idx) => (
                  <div
                    key={idx}
                    className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                  >
                    <div className="rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto bg-magenta-dark">
                      <FaFileInvoiceDollar className="m-auto" size={18} />
                    </div>
                    <div className="tw-grow mx-3 my-auto">
                      <div className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate tw-w-[calc(100vw-240px)]">
                        {item.tagihan} - {item.tahunajaran}
                      </div>
                      <div className="tw-font-medium tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate tw-line-clamp-1">
                        {item.tipe === "sekali"
                          ? `Sisa : ${toRp(item.sisabayar ?? 0)}`
                          : `Total : ${toRp(item.sisabayar ?? 0)}`}
                      </div>
                    </div>
                    <div className="tw-w-[140px]">
                      {item.opsiNominal !== "" && item.opsiNominal != null ? (
                        <div className="input-style bills has-borders input-style-always-active no-icon">
                          <label
                            htmlFor="form5"
                            className="color-highlight font-500"
                          >
                            Opsi Bayar
                          </label>
                          <select
                            value={
                              (item.tipe === "bulanan" &&
                                idxDisabledPayment.some(
                                  (disabledItem) =>
                                    disabledItem.id === item.nourut
                                )) ||
                              item.tipe !== "bulanan"
                                ? bills.find((i) => i.id === item.nourut)
                                    ?.amount ?? ""
                                : ""
                            }
                            className="bg-theme"
                            onChange={(e) => {
                              onChangeBill(e.target.value, item);
                            }}
                            // disabled={!(idx < bills.length + 1)}
                            disabled={
                              (item.tipe === "bulanan" &&
                                checkFirstIndexArr(item.nourut) &&
                                idxDisabledPayment.length === 0) ||
                              item.tipe !== "bulanan" ||
                              idxDisabledPayment.some(
                                (disabledItem) =>
                                  disabledItem.id === item.nourut
                              )
                                ? false
                                : true
                            }
                          >
                            <option value="default" disabled>
                              Opsi Bayar
                            </option>
                            <option value="">Kosong</option>
                            <option value={item.nominal}>
                              {toRp(item.nominal).replace("Rp ", "")}
                            </option>
                            {item.opsiNominal != null &&
                              item.opsiNominal !== "" &&
                              item.opsiNominal.split(",").map((item, idx) => (
                                <option key={idx} value={parseInt(item)}>
                                  {toRp(parseInt(item)).replace("Rp ", "")}
                                </option>
                              ))}
                          </select>
                          <span>
                            <i>
                              <FaChevronDown />
                            </i>
                          </span>
                        </div>
                      ) : item.opsiNominal === "" && item.tipe === "bulanan" ? (
                        <div className="input-style bills has-borders hnoas-icon input-style-always-active validate-field">
                          <input
                            type="text"
                            className="form-control bg-theme"
                            placeholder="Nominal"
                            inputMode="numeric"
                            value={
                              idxDisabledPayment.some(
                                (disabledItem) =>
                                  disabledItem.id === item.nourut
                              )
                                ? formatValue(
                                    (
                                      bills.find((i) => i.id === item.nourut)
                                        ?.amount ?? ""
                                    )
                                      ?.toString()
                                      .replace(/\D/g, "")
                                  )
                                : ""
                            }
                            onClick={(e) => {
                              const input = e.target as HTMLInputElement;
                              const value = item.sisabayar
                                .toString()
                                .replace(/\D/g, "");
                              input.value =
                                input.value === "" ? formatValue(value) : "";
                              onChangeBill(
                                input.value.toString().replace(/\D/g, ""),
                                item
                              );
                            }}
                            disabled={
                              (checkFirstIndexArr(item.nourut) &&
                                idxDisabledPayment.length === 0) ||
                              idxDisabledPayment.some(
                                (disabledItem) =>
                                  disabledItem.id === item.nourut
                              )
                                ? false
                                : true
                            }
                            readOnly
                          />
                          <label
                            htmlFor="form1"
                            className="color-highlight font-400 font-13"
                          >
                            Nominal
                          </label>
                        </div>
                      ) : (
                        <div className="input-style bills has-borders hnoas-icon input-style-always-active validate-field">
                          <input
                            type="text"
                            className="form-control bg-theme"
                            placeholder="Nominal"
                            inputMode="numeric"
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, "");
                              e.target.value = formatValue(value);
                              onChangeBill(value, item);
                            }}
                            onKeyDown={(event) => {
                              if (!/[0-9]|Backspace|Tab/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <label
                            htmlFor="form1"
                            className="color-highlight font-400 font-13"
                          >
                            Nominal
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="tw-p-8 text-center ">
                <FcFinePrint size={50} />
                <div>Tidak ada tagihan</div>
              </div>
            )}
          </div>
        </div>
      )}

      {paymentMethod === "va" && (
        <div className="card card-style !tw-mb-4">
          <div className="content mb-0">
            <div className="input-style has-borders input-style-always-active no-icon tw-grow">
              <label htmlFor="form5" className="color-highlight font-500">
                Pilih Bank
              </label>
              <select
                defaultValue={rekening?.id}
                className="bg-theme"
                onChange={(e) => {
                  const dataRekening = (methods?.data ?? []).find(
                    (i) => i.id === e.target.value
                  );
                  setRekening(dataRekening);
                  setAdminFee(dataRekening?.type === "MANUAL" ? 2000 : 3000);
                }}
              >
                <option value="default">Pilih Bank</option>
                {/* {(methods?.data ?? [])
                  .filter(
                    (i) =>
                      state.tagihan === true ||
                      (state.tagihan === false && i.type === "MANUAL")
                  )
                  .map((item, idx) => (
                    <option key={idx} value={item.id}>
                      {item.nama_bank}{" "}
                      {item.type === "MANUAL" ? " - " + item.rekening : ""}
                    </option>
                  ))} */}
                {(methods?.data ?? [])
                  .filter((i) =>
                    state.tagihan === true
                      ? i.type !== "MANUAL"
                      : i.type === "MANUAL"
                  )
                  .map((item, idx) => (
                    <option key={idx} value={item.id}>
                      {state.tagihan === true
                        ? item.nama_bank
                        : `${item.nama_bank} - ${item.rekening}`}
                    </option>
                  ))}
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="card card-style !tw-mb-4">
        <div className="content">
          <button
            className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow tw-w-full tw-h-11"
            onClick={onPayment}
          >
            Lakukan Pembayaran
          </button>
        </div>
      </div>
      <PopupCustom
        show={popupPayment}
        position="bottom"
        height={320}
        onCancel={() => setPopupPayment(false)}
      >
        <div className="pb-2 px-3">
          <h4 className="font-700 mt-3">Konfirmasi Pembayaran</h4>
          <div className="divider mt-3 mb-3"></div>
          {!state.tagihan ? (
            <>
              <div className="tw-font-bold">Kantong</div>
              <div className="tw-flex">
                <div className="tw-grow">
                  {
                    (pockets?.data ?? []).find(
                      (item) => item.typePocket === pocket
                    )?.namePocket
                  }
                </div>
                <div>
                  {toRp(pocketNominal === "" ? 0 : parseInt(pocketNominal))}
                </div>
              </div>
            </>
          ) : null}
          {bills.length > 0 ? (
            <>
              <div className="tw-font-bold mt-2">Tagihan</div>
              {bills.map((item, idx) => (
                <div key={idx} className="tw-flex">
                  <div className="tw-grow">{item.notes}</div>
                  <div>{toRp(item.amount)}</div>
                </div>
              ))}
            </>
          ) : null}
          <div className="divider !tw-my-2" />
          <div className="tw-flex">
            <div className="tw-grow">Subtotal</div>
            <div>
              {toRp(
                bills.map((i) => i.amount).reduce((a, b) => a + b, 0) +
                  (pocketNominal === "" ? 0 : parseInt(pocketNominal))
              )}
            </div>
          </div>
          {paymentMethod === "va" && (
            <div className="tw-flex">
              <div className="tw-grow">Biaya Admin</div>
              <div>{toRp(adminFee)}</div>
            </div>
          )}
          <div className="tw-flex">
            <div className="tw-grow tw-font-bold">Total</div>
            <div className="tw-font-bold">
              {toRp(
                bills.map((i) => i.amount).reduce((a, b) => a + b, 0) +
                  (pocketNominal === "" ? 0 : parseInt(pocketNominal)) +
                  (paymentMethod === "va" ? adminFee : 0)
              )}
            </div>
          </div>
          <div className="divider !tw-my-2" />
          <button
            type="submit"
            className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow my-3 tw-w-full tw-h-11"
            onClick={onConfirmPayment}
          >
            Konfirmasi Bayar (
            {toRp(
              bills.map((i) => i.amount).reduce((a, b) => a + b, 0) +
                (pocketNominal === "" ? 0 : parseInt(pocketNominal)) +
                (paymentMethod === "va" ? adminFee : 0)
            )}
            )
          </button>
        </div>
      </PopupCustom>
      <PopupError
        show={popupError}
        title="Gagal"
        message="masih ada transaksi yang belum diproses"
        textButton="Cek Transaksi"
        onConfirm={() => navigate(linkPage.FINANCE_VIRTUAL_ACCOUNT_REPORT)}
      />
      <PopupSuccess
        show={popupSuccess}
        title="Berhasil!"
        message={
          paymentMethod === "va"
            ? "Berhasil melakukan permintaan Transfer / Virtual Account (VA)"
            : "BERHASIL, Anda telah melakukan mutasi / pembayaran melalui TABUNGAN"
        }
        textButton={paymentMethod === "va" ? "Lihat Detail" : "Tutup"}
        onConfirm={() =>
          paymentMethod === "va"
            ? navigate(linkPage.FINANCE_VIRTUAL_ACCOUNT_REPORT_DETAIL, {
                replace: true,
                state: {
                  vaBankFee: rekening?.type === "MANUAL" ? 0 : adminFee,
                  vaBankName: rekening?.nama_bank,
                  vaDateExpired: result.datetime_expired,
                  vaDatePayment: null,
                  vaName: result.customer_name,
                  vaNumber: result.virtual_account,
                  vaStatus: "PENDING",
                  vaTrxAmount: result.trx_amount,
                  vaTrxId: result.trx_id,
                  type: rekening?.type,
                },
              })
            : navigate(-1)
        }
      />
    </>
  );
};

// "trx_amount": 13000,
// "nis": "2020001307",
// "customer_name": "test ibrahim yadi",
// "virtual_account": "9119708355771",
// "datetime_expired": "2023-10-12 23:05:57",
// "trx_id": "708355771",
// "jenis": "TAGIHAN",
// "request_payment": "tagihan=,;, data_saldo=TABUNGAN:::10000"
