import {
  PopupConfirm,
  PopupCustom,
  PopupSuccess,
} from "@components/generals/popup";
import { Spiner, SpinerPage } from "@components/loaders/spiner";
import {
  Checkout,
  checkoutSchema,
  CreatePin,
  createPinSchema,
} from "@components/resolvers/marketplace.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { FinanceService } from "@services/finance.service";
import { MarketplaceService } from "@services/marketplace.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation, useQuery } from "@tanstack/react-query";
import { db, MarketplaceCart } from "@utils/database";
import { useDebounce } from "@utils/debaunce";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const MarketplaceCheckoutPage = () => {
  const maxLengthChar = 200;
  const navigate = useNavigate();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const { pushNotification } = useNotificationStore((state) => state);

  const [fee, setFee] = useState<number>(1000);
  const [popupCheckout, setPopupCheckout] = useState<boolean>(false);
  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);
  const [popupConfirmSetPin, setPopupConfirmSetPin] = useState<boolean>(false);
  const [popupSetPin, setPopupSetPin] = useState<boolean>(false);
  const [cartUpdate, setCartUpdate] = useState<Partial<MarketplaceCart>>({});
  const [counter, setCounter] = useState<{ [key: number]: number }>({});
  const cartUpdateTerm = useDebounce<Partial<MarketplaceCart>>(cartUpdate, 500);

  const { register, handleSubmit } = useForm<Checkout>({
    resolver: yupResolver<Checkout>(checkoutSchema),
  });

  const { register: registerPIN, handleSubmit: handleSubmitPIN } =
    useForm<CreatePin>({
      resolver: yupResolver<CreatePin>(createPinSchema),
    });

  const { data: pockets } = useQuery({
    queryKey: [FinanceService.queries.GET_FINANCE_POCKET],
    queryFn: () => FinanceService.pocket(),
  });

  const carts = useLiveQuery(() => db.carts.toArray());

  const { data: cacheCarts } = useQuery({
    queryKey: [
      MarketplaceService.queries.GET_MARKETPLACE_PRODUCTS_IS_READY,
      ...(carts?.map((item) => item.id?.toString() ?? "") ?? []),
    ],
    queryFn: async () => {
      let data = typeof carts === "undefined" ? [] : carts;
      if (data.length === 0) return [];
      let dataDb = await MarketplaceService.checkProductIsReady(
        carts?.map((item) => item.id?.toString() ?? "") ?? []
      );
      return data.map((item) => {
        return {
          ...item,
          isReady:
            dataDb.data?.find((itemDb) => itemDb.id === item.id?.toString())
              ?.isReady ?? "N",
        };
      });
    },
  });

  const { mutate: checkPin, isPending: isPendingCheckPin } = useMutation({
    mutationFn: MarketplaceService.checkPin,
    onSuccess: (response) => {
      if (response.success) {
        onCheckout();
      } else if (response.errorCode === "E_UNPROCESSABLE_ENTITY") {
        setPopupConfirmSetPin(true);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  const { mutate: createPin, isPending: isPendingCreatePin } = useMutation({
    mutationFn: MarketplaceService.createPin,
    onSuccess: (response) => {
      pushNotification({
        type: response.success ? "success" : "error",
        message: response.message,
      });
      setPopupSetPin(false);
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: MarketplaceService.checkout,
    onSuccess: (response) => {
      if (response.success) {
        setPopupCheckout(false);
        setFee(1000);
        setPopupSuccess(true);
        db.carts.where("id").anyOf(response.data).delete();
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Daftar Keranjang",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Daftar Keranjang" });
  }, [setFooter, setHeader, setAppBar]);

  useEffect(() => {
    if (cartUpdateTerm.id) db.carts.update(cartUpdateTerm.id, cartUpdateTerm);
  }, [cartUpdateTerm]);

  useEffect(() => {
    if (carts && cacheCarts && carts.length > 0 && cacheCarts.length > 0) {
      carts.forEach((cart) => {
        const cacheCart = cacheCarts.find((item) => item.id === cart.id);
        if (cart.check && cacheCart && cacheCart.isReady !== "Y") {
          cart.id && db.carts.update(cart.id, { check: false });
        }
      });
    }
  }, [carts, cacheCarts]);

  const onDeleteCart = async (cart: MarketplaceCart) => {
    if (cart.id) db.carts.delete(cart.id);
    pushNotification({
      type: "success",
      message: `berhasil hapus produk ${cart.name} dari keranjang`,
    });
  };

  const onCheck = async (cart: MarketplaceCart) => {
    if (cart.id) db.carts.update(cart.id, { check: !cart.check });
  };

  const onCheckout = () => {
    const amount =
      carts
        ?.map((item) => (item.check ? item.price * item.qty : 0))
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0) ?? 0;
    if (amount <= 0) {
      pushNotification({
        type: "error",
        message: "Belum ada barang yang dipilih!",
      });
      return;
    }
    setPopupCheckout(true);
  };

  const onPayment = async (checkout: Checkout) => {
    if (isPending) return;
    const balancePocket =
      pockets?.data?.find((item) => item.typePocket === checkout.paymentMethod)
        ?.balance ?? 0;
    if (checkout.amount > balancePocket) {
      pushNotification({
        type: "error",
        message: "Jumlah pembayaran melebihi saldo kantong yang dipilih!",
      });
      return;
    }
    const dataCarts = carts
      ?.filter((item) => item.check)
      .map((item) => ({
        id: item.id ?? 0,
        qty: item.qty,
        notes: item.message,
      }));
    mutate({
      pin: `${checkout.pin}`,
      paymentMethod: checkout.paymentMethod,
      paymentFee: checkout.paymentFee,
      carts: dataCarts ?? [],
    });
  };

  const onCreatePin = async (payload: CreatePin) => {
    if (
      payload.newPin !== payload.confirmPin ||
      `${payload.newPin}`.length > 6 ||
      `${payload.confirmPin}`.length > 6
    ) {
      pushNotification({
        type: "error",
        message: "PIN yang kamu masukan tidak sama",
      });
      return;
    }
    createPin(`${payload.confirmPin}`);
  };

  return (
    <>
      <SpinerPage show={isPending} />
      <div className="card card-style">
        <div className="content mt-3">
          {carts?.map((item, idx) =>
            cacheCarts?.map(
              (cart, keyCart) =>
                cart.id === item.id && (
                  <div key={idx}>
                    <div className="d-flex align-items-center mb-2">
                      <div className="me-2">
                        <img
                          src={item.picture}
                          alt="product"
                          className="rounded-m shadow-xl"
                          width="96"
                          height="96"
                        />
                      </div>
                      <div className="d-flex tw-flex-col tw-grow">
                        <div className="color-theme tw-font-light tw-text-base tw-line-clamp-2 tw-h-12">
                          {item.name}
                        </div>
                        <div className="color-theme tw-font-semibold tw-text-xl mt-2">
                          {toRp(item.price)}
                        </div>
                        <div key={keyCart}>
                          {cart.isReady === "Y" ? (
                            <span className="badge bg-green-dark color-white font-10 px-2 rounded-2 tw-w-24">
                              TERSEDIA
                            </span>
                          ) : (
                            <span className="badge bg-red-dark color-white font-10 px-2 rounded-2 tw-w-24">
                              TIDAK TERSEDIA
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="tw-w-20 ms-2 d-flex tw-flex-col tw-flex-none">
                        <div className="input-style has-borders input-style-always-active no-icon my-auto">
                          <label
                            htmlFor="form5"
                            className="color-highlight font-500"
                          >
                            Jumlah
                          </label>
                          <select
                            defaultValue={item.qty}
                            className="bg-theme"
                            onChange={(e) =>
                              setCartUpdate({
                                id: item.id!,
                                qty: parseInt(e.target.value),
                                message: item.message,
                              })
                            }
                          >
                            <option value="default" disabled>
                              Jumlah
                            </option>
                            {Array.from({ length: 100 }, (_, i) => (
                              <option key={i} value={i + 1}>
                                {i + 1} Pcs
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex mt-2"
                      style={{ marginBottom: "-15px" }}
                    >
                      {cart.isReady === "Y" && (
                        <div
                          key={keyCart}
                          className="tw-mr-2 tw-flex-none mt-2"
                          onClick={() => onCheck(item)}
                        >
                          <i
                            className={`icon-check-2 fa fa-check-square tw-text-2xl ${
                              item.check && "color-highlight"
                            }`}
                          ></i>
                        </div>
                      )}
                      <div className="input-style has-borders no-icon tw-grow">
                        <textarea
                          onChange={(e) => {
                            setCartUpdate({
                              id: item.id!,
                              qty: item.qty,
                              message: e.target.value,
                            });
                            const counter =
                              maxLengthChar - e.target.value.length;
                            setCounter((prevCounters) => ({
                              ...prevCounters,
                              [idx]: counter,
                            }));
                          }}
                          id={`form-message-${idx}`}
                          // defaultValue={item.message}
                          defaultValue={""}
                          placeholder="masukkan catatan Anda"
                          maxLength={maxLengthChar}
                        ></textarea>
                        <p className="tw-text-xs">
                          karakter tersisa:{" "}
                          {counter[idx] ?? (0 || maxLengthChar)}
                        </p>
                        <label
                          htmlFor={`form-message-${idx}`}
                          className="color-highlight"
                        >
                          masukkan catatan Anda
                        </label>
                      </div>
                      <div>
                        <button
                          onClick={() => onDeleteCart(item)}
                          className="btn btn-sm tw-w-20 tw-h-12 ms-2 rounded-xs text-uppercase font-900 shadow-s bg-red-dark"
                        >
                          Hapus
                        </button>
                      </div>
                    </div>
                    <div className="divider my-3"></div>
                  </div>
                )
            )
          )}
          <div>
            <h6 className="tw-font-normal">Total Bayar</h6>
            <h4>
              {toRp(
                carts
                  ?.map((item) =>
                    cacheCarts?.map((cart) =>
                      item.check && cart.id === item.id && cart.isReady === "Y"
                        ? item.price * item.qty
                        : 0
                    )
                  )
                  .flat()
                  .reduce((accumulator = 0, currentValue = 0) => {
                    return accumulator + currentValue;
                  }, 0) ?? 0
              )}
            </h4>
            <button
              onClick={() => (isPendingCheckPin ? null : checkPin())}
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 tw-w-full tw-h-11"
            >
              {isPendingCheckPin ? <Spiner /> : "Checkout"}
            </button>
          </div>
        </div>
      </div>
      <PopupCustom
        show={popupCheckout}
        position="bottom"
        height={290}
        onCancel={() => setPopupCheckout(false)}
      >
        <div className="pb-2 px-3">
          <h4 className="font-700 mt-3">Konfirmasi Pembayaran</h4>
          <div className="divider mt-3 mb-4"></div>
          <form autoComplete="off" onSubmit={handleSubmit(onPayment)}>
            <input
              type="hidden"
              value={
                carts
                  ?.map((item) =>
                    cacheCarts?.map((cart) =>
                      item.check && cart.id === item.id && cart.isReady === "Y"
                        ? item.price * item.qty
                        : 0
                    )
                  )
                  .flat()
                  .reduce((accumulator = 0, currentValue = 0) => {
                    return accumulator + currentValue;
                  }, 0) ?? 0
              }
              {...register("amount", {
                value:
                  carts
                    ?.map((item) =>
                      cacheCarts?.map((cart) =>
                        item.check &&
                        cart.id === item.id &&
                        cart.isReady === "Y"
                          ? item.price * item.qty
                          : 0
                      )
                    )
                    .flat()
                    .reduce((accumulator = 0, currentValue = 0) => {
                      return accumulator + currentValue;
                    }, 0) ?? 0,
              })}
            />
            <div className="input-style has-borders input-style-always-active no-icon mb-4">
              <label htmlFor="form5" className="color-highlight font-500">
                Pilih Kantong Bayar
              </label>
              <select
                defaultValue="ABO"
                className="bg-theme"
                {...register("paymentMethod", { value: "ABO" })}
              >
                <option value="default" disabled>
                  Pilih Kantong Bayar
                </option>
                {pockets?.data?.map((item, idx) => (
                  <option
                    key={idx}
                    value={item.typePocket}
                    disabled={item.typePocket !== "ABO"}
                  >
                    {item.namePocket} ({toRp(item.balance)})
                  </option>
                ))}
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
            <div className="input-style has-borders input-style-always-active no-icon mb-4">
              <label htmlFor="form5" className="color-highlight font-500">
                Biaya Layanan
              </label>
              <select
                defaultValue={fee}
                className="bg-theme"
                {...register("paymentFee")}
                onChange={(e) => setFee(parseInt(e.target.value))}
              >
                <option value="default" disabled>
                  Biaya Layanan
                </option>
                {Array.from({ length: 5 }, (_, i) => (
                  <option key={i} value={(i + 1) * 1000}>
                    {toRp((i + 1) * 1000)}
                  </option>
                ))}
                <option value="0">Rp 0</option>
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="password"
                className="form-control bg-theme"
                placeholder=""
                {...register("pin")}
                minLength={6}
                maxLength={6}
                inputMode="numeric"
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                PIN Transaksi
              </label>
            </div>

            <button
              type="submit"
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
            >
              Konfirmasi Bayar (
              {toRp(
                (carts
                  ?.map((item) =>
                    cacheCarts?.map((cart) =>
                      item.check && cart.id === item.id && cart.isReady === "Y"
                        ? item.price * item.qty
                        : 0
                    )
                  )
                  .flat()
                  .reduce((accumulator = 0, currentValue = 0) => {
                    return accumulator + currentValue;
                  }, 0) ?? 0) + fee
              )}
              )
            </button>
          </form>
        </div>
      </PopupCustom>
      <PopupSuccess
        show={popupSuccess}
        title="Berhasil!"
        message="Berhasil membuat pesanan, silahkan lihat daftar pesanan!"
        textButton="Lihat Pesanan"
        onConfirm={() =>
          navigate(linkPage.MARKETPLACE_HISTORY, { replace: true })
        }
      />
      <PopupConfirm
        height={200}
        show={popupConfirmSetPin}
        title="Belum ada PIN"
        message="PIN transaksi anda saat ini belum dibuat, buat PIN transaksi sekarang ? "
        textConfirm="Ya, Buat"
        textCancel="Kembali"
        onConfirm={() => {
          setPopupSetPin(true);
          setPopupConfirmSetPin(false);
        }}
        onCancel={() => setPopupConfirmSetPin(false)}
      />

      <PopupCustom
        show={popupSetPin}
        position="bottom"
        height={290}
        onCancel={() => setPopupSetPin(false)}
      >
        <div className="pb-2 px-3">
          <h4 className="font-700 mt-3">Buat PIN Transaksi</h4>
          <div className="divider mt-3 mb-4"></div>
          <form autoComplete="off" onSubmit={handleSubmitPIN(onCreatePin)}>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="password"
                className="form-control bg-theme"
                placeholder=""
                {...registerPIN("newPin")}
                minLength={6}
                maxLength={6}
                inputMode="numeric"
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Masukan PIN
              </label>
            </div>

            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="password"
                className="form-control bg-theme"
                placeholder=""
                {...registerPIN("confirmPin")}
                minLength={6}
                maxLength={6}
                inputMode="numeric"
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Konfirmasi PIN
              </label>
            </div>

            <button
              type="submit"
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
            >
              {isPendingCreatePin ? <Spiner /> : "Buat PIN Transaksi"}
            </button>
          </form>
        </div>
      </PopupCustom>
    </>
  );
};
