import { PopupCustom } from "@components/generals/popup";
import { SpinerPage } from "@components/loaders/spiner";
import {
  FilterProduct,
  filterProductSchema,
} from "@components/resolvers/marketplace.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { Product } from "@services/dtos/marketplace.dto";
import { MarketplaceService } from "@services/marketplace.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { db } from "@utils/database";
import { useDebounce } from "@utils/debaunce";
import { toRp } from "@utils/helper";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineFilter } from "react-icons/ai";
import { BsFillCartPlusFill, BsWhatsapp } from "react-icons/bs";
import { FaChevronDown, FaSearch } from "react-icons/fa";
import { IoStorefront } from "react-icons/io5";
import { useLocation } from "react-router-dom";

export const MarketplaceProductPage = () => {
  const { state } = useLocation();
  const [filter, setFilter] = useState<FilterProduct>({
    search: state?.id ?? "",
    category: "",
    merchant: "",
  });
  const [popupFilter, setPopupFilter] = useState<boolean>(false);
  const { pushNotification } = useNotificationStore((state) => state);
  const { setFooter, setHeader, setAppBar, setBottomNavigation } =
    useThemeStore((state) => state);
  const filterTerm = useDebounce<FilterProduct>(filter, 500);

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const { register, handleSubmit } = useForm<FilterProduct>({
    resolver: yupResolver<FilterProduct>(filterProductSchema),
  });

  const { data, isPending } = useQuery({
    queryKey: [MarketplaceService.queries.GET_MARKETPLACE_PRODUCTS, filterTerm],
    queryFn: () => MarketplaceService.products(filterTerm),
    gcTime: 3 * 60 * 1000,
    staleTime: 3 * 60 * 1000,
    refetchOnWindowFocus: true,
  });

  const { data: dataMerchant } = useQuery({
    queryKey: [MarketplaceService.queries.GET_MARKETPLACE_MERCHANT],
    queryFn: () => MarketplaceService.merchant(),
    gcTime: 3 * 60 * 1000,
    staleTime: 3 * 60 * 1000,
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Al-Barokah",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ leading: "back", title: "Al-Barokah", actions: [] });
    setBottomNavigation("marketplace");
  }, [setFooter, setHeader, setAppBar, setBottomNavigation]);

  const onFilterProduct = (filterProduct: FilterProduct) => {
    setFilter({ ...filter, ...filterProduct });
  };

  const onAddOnCart = (product: Product) => {
    db.carts
      .add({
        id: product.id,
        check: false,
        name: product.productName,
        picture: product.productPicture,
        price: product.sellPrice,
        qty: 1,
        message: "",
        stockReady: product.stockReady,
        openTime: product.openTime,
        closeTime: product.closeTime,
      })
      .then(() => {
        pushNotification({
          type: "success",
          message: `berhasil masukan keranjang produk ${product.productName}`,
        });
      })
      .catch((e) => {
        if (e.message.includes("already exists")) {
          pushNotification({
            type: "info",
            message: `produk ${product.productName} sudah ada dikeranjang`,
          });
          return;
        }
        pushNotification({
          type: "error",
          message: `gagal masukan produk ${product.productName}`,
        });
      });
  };

  return (
    <>
      <SpinerPage show={isPending} />
      <div className="content mb-2 tw-relative tw-z-10 d-flex pt-4">
        <div className="search-box search-dark shadow-m border-0 bg-theme rounded-m bottom-0 tw-grow">
          <i className="fa-search ms-n3">
            <FaSearch />
          </i>
          <input
            type="text"
            className="border-0"
            placeholder="Masukan Kata Kunci Produk ..."
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
          />
        </div>
        <button
          onClick={() => setPopupFilter(true)}
          className="btn btn-xs btn-full rounded-m text-uppercase font-900 shadow-s bg-theme tw-w-12 tw-ml-2"
        >
          <AiOutlineFilter size={20} className="tw-text-gray-500" />
        </button>
      </div>

      <div className="tw-mx-4 tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-4 tw-mt-5">
        {data?.data?.map((item, idx) => (
          <div key={idx} className="item bg-theme pb-3 rounded-m shadow-l">
            <div
              className="card mb-3 tw-rounded-t-[20px]"
              style={{
                height: "175px",
                backgroundImage: `url(${item.productPicture})`,
              }}
            />
            <div className="d-flex flex-column px-3">
              <div className="tw-opacity-60 !tw-leading-none color-theme tw-h-10 tw-line-clamp-3">
                {item.productName}
              </div>
              <h5 className="my-1 tw-font-medium">{toRp(item.sellPrice)}</h5>
              {item.stockReady === "Y" ? (
                <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-w-24">
                  TERSEDIA
                </span>
              ) : (
                <span className="badge bg-red-dark color-white font-10 tw-py-1 px-2 rounded-2 tw-w-24">
                  TIDAK TERSEDIA
                </span>
              )}
              <div className="d-flex my-2">
                <div className="tw-w-6 tw-mr-1">
                  <IoStorefront size={20} />
                </div>
                <div className="tw-leading-3 my-auto tw-line-clamp-2">
                  {item.merchantName ?? "-"}
                </div>
              </div>
              <div className="d-flex">
                <button
                  onClick={() => onAddOnCart(item)}
                  className="btn btn-xs btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow"
                  disabled={item.stockReady === "N"}
                >
                  <BsFillCartPlusFill size={16} />{" "}
                  {windowWidth > 463 && "KERANJANG"}
                </button>
                <button className="btn btn-xs btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-w-11 tw-ml-2">
                  <BsWhatsapp size={16} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <PopupCustom
        show={popupFilter}
        position="bottom"
        height={260}
        onCancel={() => setPopupFilter(false)}
      >
        <div className="pb-2 px-3">
          <h4 className="font-700 my-3">Filter Produk</h4>
          <form autoComplete="off" onSubmit={handleSubmit(onFilterProduct)}>
            <div className="input-style has-borders input-style-always-active no-icon mb-4">
              <label htmlFor="form5" className="color-highlight font-500">
                Jenis
              </label>
              <select
                defaultValue=""
                className="bg-theme"
                {...register("category")}
              >
                <option value="default" disabled>
                  Jenis
                </option>
                <option value="">Semua</option>
                <option value="makanan">Makanan</option>
                <option value="minuman">Minuman</option>
                <option value="pakaian">Pakaian</option>
                <option value="herbal">Herbal</option>
                <option value="skincare">Skincare</option>
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
            <div className="input-style has-borders input-style-always-active no-icon mb-4">
              <label htmlFor="form5" className="color-highlight font-500">
                Toko
              </label>
              <select
                defaultValue=""
                className="bg-theme"
                {...register("merchant")}
              >
                <option value="default" disabled>
                  Toko
                </option>
                <option value="">Semua Toko</option>
                {dataMerchant?.data?.map((item, idx) => (
                  <option key={idx} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>

            <button
              type="submit"
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
            >
              Terapkan Filter
            </button>
          </form>
        </div>
      </PopupCustom>
    </>
  );
};
