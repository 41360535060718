import { ComponentProps } from "@declarations/props";
import { SchoolService } from "@services/school.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { linkPage } from "@utils/router";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export const Header: ComponentProps<{
  height?: number;
  classOverlay?: string;
}> = ({ children, classOverlay, height = 210 }) => {
  return (
    <>
      {children ?? <PageTitle />}
      <div
        className={`card header-card shape-rounded ${classOverlay}`}
        style={{ height: `${height}px` }}
      >
        <div className="card-overlay bg-highlight opacity-95"></div>
        <div className="card-overlay dark-mode-tint"></div>
        <div className="card-bg preload-img entered loaded bg-header-footer-default"></div>
      </div>
    </>
  );
};

const PageTitle = () => {
  const navigate = useNavigate();
  const { header } = useThemeStore((state) => state);
  const { data } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    queryFn: SchoolService.studentBio,
  });

  return (
    <div className={`page-title page-title-${header.type ?? "large"}`}>
      <h2 className="greeting-text">
        {header.buttonBack && (
          <span
            onClick={() => navigate(-1)}
            className="tw-p-1 tw-pe-2 tw-cursor-pointer"
          >
            <FaArrowLeft size={20} />
          </span>
        )}
        {header.title ?? (
          <>
            Pondok Pesantren <br /> Al - Ma'tuq
          </>
        )}
      </h2>
      {header.avatar && (
        <Link
          to={linkPage.HOME_PROFILE}
          replace={true}
          data-menu="menu-main"
          className={`bg-fade-highlight-light shadow-xl preload-img ${
            data?.data?.gender === "Pria" ||
            data?.data?.gender === "PRIA" ||
            data?.data?.gender === ""
              ? "bg-image-avatar-default"
              : "bg-image-avatar-female-default"
          } `}
        />
      )}
    </div>
  );
};
